import { useState, useEffect, useMemo, useCallback } from "react";
import { useRouter } from "next/router";
//Components
//Recoil
import { useAtoms } from "../../recoil/hooks";
//Api
import EastIcon from '@mui/icons-material/East';
//Locale
import useTranslation from "next-translate/useTranslation";
import { isMobile } from 'react-device-detect';
import Spinner from "@components/Spinner";
import { Button } from "@components/button";
import { HomeScreenButtonCard } from "./components/HomeScreenButtonCard";
import { PATH_MAIN_MANAGMENT, PATH_BUY, PATH_PROFILE, PATH_OPERATION_MANAGEMENT, PATH_PURCHASE } from "@constants";

const HomeScreen = () => {
    const router = useRouter();
    const { state } = useAtoms();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [onClient, setOnClient] = useState(false);
    const pageTranslationCode = `home${process.env.LOCALES_SUFFIX}`;

    const routeTo = useCallback((path, category = null) => {
      setLoading(true);
      let route = path;
      if (category) {
          route += `/${category}`;
      }
      router.push(route, null, { shallow: true });
    },[])
  
    /*  The visibility of the menu items is controlled by the following env defined object:
        MENU_VISIBILITY_PER_ROLE={"exhibitor":{"invite":true,"operational":true},"school":{"invite":true,"operational":false},"agency":{"invite":true,"operational":false},"office":{"invite":true,"operational":true},"partner":{"invite":true,"operational":false}}
    */
   const handleElementVisibility = (role, elementDomain) => {
       const MENU_VISIBILITY_PER_ROLE = JSON.parse(process.env.MENU_VISIBILITY_PER_ROLE);
        if (MENU_VISIBILITY_PER_ROLE[role]) {
            return MENU_VISIBILITY_PER_ROLE[role][elementDomain];
        }
        return false;
    }

    const handleInvoiceVisibility = () => {
        return state.invoiceAvailable
    }

   useEffect(() => {
       if (isMobile) {
           router.push(PATH_MAIN_MANAGMENT, null, { shallow: true });
           return;
        }
        setOnClient(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const userDisplayName = useMemo(() => {
        return state.customer?.firstname || "";
    }, [state.customer, onClient]);
 
    const operationsEnabled = useMemo(() => {
        return handleElementVisibility(state.customer?.customer_type, "operational") && onClient;
    }, [state.customer, onClient]);
 
    const invitationsEnabled = useMemo(() => {
        return handleElementVisibility(state.customer?.customer_type, "invite") && onClient;
    }, [state.customer, onClient]);

    const invoicesEnabled = useMemo(() => {
        return handleInvoiceVisibility(state.customer?.customer_type) && onClient;
    }, [state.customer, onClient]);

    const spinner = loading && <Spinner />;

    const renderButton = (onClick, label) => (
      <Button
        onClick={onClick}
        variant="secondary"
        css="justify-between p-3 rounded-lg hover:bg-app-defaultColor hover:text-app-textHover"
        label={label}
        labelClass="font-semibold"
        endIcon={<EastIcon className="icon-arrow-mui"/>}
      />
    );

    const RenderButtonsBuyTicketingOperation = () => {
      const buttons = [];
     if(process.env.IS_DIREZIONE !== "true") {
        if (invitationsEnabled) {
          buttons.push(renderButton(() => routeTo(PATH_BUY, "g"), t(`${pageTranslationCode}:header.buy_invitations`)));
        }
    
        if (operationsEnabled) {
          buttons.push(renderButton(() => routeTo(PATH_BUY, "o"), t(`${pageTranslationCode}:header.buy_passes`)));
        }
    }
  
      return buttons;
    };
  

    return (
        <>
            {spinner}
            <div className={`flex flex-col p-10 mx-auto space-y-10 ${state.sidebarShowing ? 'md:w-4/5' : 'md:w-11/12'}`}>
                <div className="flex items-center justify-start pl-10 pr-8 pb-4 w-full h-40 rounded-lg" style={{
                    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1"
                }}>
                    {onClient && (
                        <h1 className="text-6xl">
                            {t(`${pageTranslationCode}:banner.welcome`, { user: userDisplayName })}
                        </h1>
                    )}
                </div>

                <div className="flex items-center">
                    <RenderButtonsBuyTicketingOperation />
                </div>

                <div className="grid grid-cols-2 gap-4">
                    {
                      invitationsEnabled 
                      && <HomeScreenButtonCard 
                          title={t(`${pageTranslationCode}:body.handle_invitations_button.title`)} 
                          description={t(`${pageTranslationCode}:body.handle_invitations_button.description`)} 
                          onClick={() => routeTo(PATH_MAIN_MANAGMENT)} 
                      />
                    }
                    { 
                      operationsEnabled 
                      && <HomeScreenButtonCard 
                            onClick={() => routeTo(PATH_OPERATION_MANAGEMENT)}
                            title={t(`${pageTranslationCode}:body.handle_passes_button.title`)} 
                            description= {t(`${pageTranslationCode}:body.handle_passes_button.description`)} 
                          /> 
                    }
                    <HomeScreenButtonCard 
                      onClick={() => routeTo(PATH_PURCHASE)}
                      title={t(`${pageTranslationCode}:body.orders_history_button.title`)} 
                      description={t(`${pageTranslationCode}:body.orders_history_button.title`)}
                    /> 
                    {
                     invoicesEnabled 
                     && <HomeScreenButtonCard 
                       onClick={() => routeTo(PATH_PROFILE)}
                       title={t(`${pageTranslationCode}:body.notify_anomaly_button.title`)} 
                       description={t(`${pageTranslationCode}:body.notify_anomaly_button.description`)}
                     /> 
                    }
                </div>
                <p className="text-sm text-app-mediumgrey">{`${t(`${pageTranslationCode}:customer-care-contact-message`)} `} <a  className="font-semibold underline underline-offset-1 text-app-darkbg text-m" href={"mailto:"+ process.env.CUSTOMER_CARE_EMAIL }>{process.env.CUSTOMER_CARE_EMAIL}</a>  </p>
            </div>
        </>
    );
};

export default HomeScreen;
